import { Link, Navigate } from "react-router-dom"; 
import React, { Component } from "react";
import { AppContext } from "../config/common";
import Catalog from "../Catalog";
import { Form, Button, Alert } from "react-bootstrap";
import FormField from "../widgets/FormField";
import { SiteService } from "../services/site";

class LoginController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    const frmId = "frmLogin" + new Date().getTime();
    this.state = {
      ...props,
      formId: frmId,
      formData: {
        identifier: null,
        password: null,
      },
      errorData: {
        identifier: null,
        password: null,
      },
      error:null, 
      isloaded:false
    };
  }
  componentDidMount() { 
    this.intervalId=setInterval(this.refreshlist, 1000); 
  }
  componentWillUnmount() { 
    clearInterval(this.intervalId);
  }
  refreshlist=async ()=>{ 
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { settings } = this.context;
    let formData = this.state.formData; 
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
        errorData[name] = "The " + name + " field is required";
        if (Object.hasOwnProperty.call(formData, name)) {
          if (formData[name] && formData[name] !== "") {
            errorData[name] = null;
          } else {
            isInValid = true;
          }
        } else {
          isInValid = true;
        }
      }
      let isSuccess = false;
      let isSubmit = isInValid ? false : true; 
      let error=null
      this.setState({ errorData, isSubmit, isSuccess,error });
      if (!isInValid) {
        const res = await SiteService.loginUser(formData, settings);
        if(res.user){
            Catalog.Session={
                isLogined:true,
                token:res.jwt,
                user:res.user,
            }; 
        }
        if(res.error){
          error=res.error.message;
        }
        isSubmit=false;
      }
      this.setState({ errorData, isSubmit, isSuccess,error });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });
    if (!value || value === "") {
      const errorData = {
        ...this.state.errorData,
        [name]: "The " + name + " field is required",
      };
      this.setState({ errorData });
    } else {
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    }
  };
  render() {
    const { settings } = this.context;
    return <><div className="loginWrapp">
        {(!this.state.isloaded || settings.show_auth)?(
            <>
            {(Catalog.Session.isLogined)?(<><Navigate replace to={Catalog.RediarectLogin} /></>):(<div className="row row-one loginContainer">
              <div className="col-md-4">
                <div className="log-head">
                  <h3>Log In</h3>
                  {/* <img src={logo} alt="logo" /> */}
                </div>
                {
                  this.state.error&&(<>
                  <Alert variant="danger" dismissible>
                    <p>
                      {this.state.error}
                    </p>
                  </Alert>
                  
                  </>)
                }
                {
                  Catalog.isMessage&&(<>                  
                  <Alert variant="success" dismissible>
                    <p>
                      {Catalog.Message}
                    </p>
                  </Alert>
                  </>)
                }
                <Form
                  id={this.state.formId}
                  className="loginForm"
                  onSubmit={this.handleSubmit}
                  noValidate
                >
                  <FormField
                    id={this.state.formId + "identifier"}
                    title={"Email Id"}
                    // placeholder={"Email Id"}
                    value={this.state.formData?.identifier}
                    error={this.state.errorData?.identifier}
                    name={"identifier"}
                    handle={this.handleChange}
                  />
                  <FormField
                    id={this.state.formId + "password"}
                    type={"password"}
                    title={"Password"}
                    // placeholder={"Password"}
                    value={this.state.formData?.password}
                    error={this.state.errorData?.password}
                    name={"password"}
                    handle={this.handleChange}
                  />
                  <Link to={"/forgot-password"} className="frgtpw">
                    Forgot Password?
                  </Link>
                  <div className="formfield">
                  <Button
                    variant="primary"
                    className="loginBtn1"
                    type="submit"
                    disabled={this.state.isSubmit}
                >
                    Login
                </Button>
                  </div>
                  <div className="loginFooter">
                    <p>
                      New to {settings.title}?<Link to={"/signup"}>Register</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>)}
            </>
        ):( <>
          <Navigate replace to="/404" />
        </>)}
    </div></> 
  }
}
export default LoginController;
