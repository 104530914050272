import React, { Component } from "react";
import { Helmet } from "react-helmet";
import SearchForm from "./../widgets/SearchForm";
import BrandSlider from "../widgets/BrandSlider";
import NavTabBar from "../widgets/NavTabBar";
import SideBarAdCard from "../widgets/SideBarAdCard";
import NewsCardSlider from "../widgets/NewsCardSlider";
import TrendingNewsSlider from "../widgets/TrendingNewsSlider";
import LatestNewsCard from "../widgets/LatestNewsCard"; 
import NewsList from "../widgets/NewsList";

import "./../css/widget/SideBarAdCard.css";
import { HomeService } from "./../services/home";
import { NewsService } from "./../services/news";
import { AdsService } from "./../services/ads";

import { AppContext } from "./../config/common";

class HomeController extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      products: { data: [] },
      news: {},
      latestnews: [],
      ads: {
        sidebar: [],
        onecol: [],
        twocol: [],
      },
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0); 
    this.fetchProducts();
    this.fetchCategory();
    this.fetchLatestPost();
    this.fetchAds();
  }
  fetchAds = async () => {
    const ads = await AdsService.fetchAds();
    this.setState({ ads });
  };
  fetchProducts = async () => {
    const products = await HomeService.fetchProducts();
    this.setState({ products });
  };
  fetchCategory = async () => {
    const category = await HomeService.fetchCategory();
    this.setState({ category });
  };
  fetchLatestPost = async () => {
    const latestnews = await NewsService.fetchLatestPost();
    this.setState({ latestnews });
  };
  handleSubmit = async (e) => {
    console.log("Form submitted:");
  };
  render() {
    const { settings } = this.context;
    return (
      <>
      <Helmet>
        <title>Legal Tech  and Taxation News Portal - Trends, Insights, and Updates</title>

        <meta name="description" content="Discover Tech Legal Online for expert insights on tech law, compliance, and digital innovation. Stay informed with the latest trends and legal updates. Read More!"/>

        <meta name="keywords" content="Legal Services, Practise Management System" />


      </Helmet>
      
      <div className="home-page-outer">
        {settings.show_products ? (
          <div className="brand-wrp">
            <div className="slider-wrap slide-desk">
              <BrandSlider
                products={this.state.products.data}
                ismobile={false}
              ></BrandSlider>
            </div>

            <div className="slider-wrap slide-mob">
              <BrandSlider
                products={this.state.products.data}
                ismobile={true}
              ></BrandSlider>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row main-menu">
          <div className="col-md-9">
            <NavTabBar categorys={this.state.category} active="all"></NavTabBar>
          </div>
          <div className="col-md-3">
            <div className="search-news">
              <SearchForm
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="row row-two">
          <div className="col-md-9">
            <div className="row  split-row row-one">
              <div className=" col-md-6 block-col1 ">
                <TrendingNewsSlider></TrendingNewsSlider>
                <NewsCardSlider news={this.state.latestnews}></NewsCardSlider>
              </div>
              <div className=" col-md-6 block-col2  ">
                <LatestNewsCard news={this.state.latestnews}></LatestNewsCard>
              </div>
            </div> 
            <div className="row">
              {this.state.category.map((cat, idx) => {
                return idx % 4 === 0 || idx % 4 === 2 || idx === 0 ? (
                  <NewsList
                    key={idx}
                    ads={this.state.ads.onecol}
                    adm={idx % 4 === 2 ? "twocol" : "onecol"}
                    category={cat.slug}
                    tag={cat.title}
                  ></NewsList>
                ) : (
                  <NewsList
                    key={idx}
                    category={cat.slug}
                    tag={cat.title}
                  ></NewsList>
                );
              })}
            </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard></SideBarAdCard> 
          </div>
        </div>
      </div>

      </>
    );
  }
}

export default HomeController;
