
import Catalog from "../Catalog";
import { authrequest, gustrequest, multipart, request } from "./../config/request" ;
import qs from 'qs';
export const SiteService = {
    async fetchCategories(page=1){   
        const states=await request.get(`/api/catelog-categories?pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let category = response.data.data[i]
                   proData.data.push({
                        id:category.id,
                        ...category.attributes
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },
    async fetchTags(page=1){   
        const states=await request.get(`/api/book-tags?pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let tag = response.data.data[i]
                   proData.data.push({
                        id:tag.id,
                        ...tag.attributes
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },
    fetchAllLanguages(params={}){
        params = qs.stringify(params, {
            encodeValuesOnly: true, // prettify URL
          });
        return request.get(`/api/languages?${params}`).then(res=> res?.data);
    },
    async fetchLanguages(page=1){   
        const states=await request.get(`/api/languages?pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let language = response.data.data[i]
                   proData.data.push({
                        id:language.id,
                        ...language.attributes
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },
    fetchAllCourts(params={}){
        params = qs.stringify(params, {
            encodeValuesOnly: true, // prettify URL
          });
        return request.get(`/api/courts?${params}`).then(res=> res?.data);
    },
    async fetchCourts(page=1){   
        const states=await request.get(`/api/courts?populate[country_state]=true&populate[state_district]=true&populate[city]=true&pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let court = response.data.data[i]
                   let {country_state ,state_district,city,...attributes} = court.attributes
                   proData.data.push({
                        id:court.id,
                        ...attributes,
                        state:{
                            id:country_state.data?.id,
                            ...country_state.data?.attributes,
                        },
                        district:{
                            id:state_district.data?.id,
                            ...state_district.data?.attributes,
                        },
                        city:{
                            id:city.data?.id,
                            ...city.data?.attributes,
                        }
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            console.log("error---",error)
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },
    fetchAllSpecializations(params={}){
        params = qs.stringify(params, {
            encodeValuesOnly: true, // prettify URL
          });
        return request.get(`/api/specializations?${params}`).then(res=> res?.data);
    },
    async fetchSpecializations(page=1){   
        const states=await request.get(`/api/specializations?pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let state = response.data.data[i]
                   proData.data.push({
                        id:state.id,
                        ...state.attributes
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },
    async allCities(params={}){
        params = qs.stringify(params, {
            encodeValuesOnly: true, // prettify URL
          });
      return request.get(`/api/cities?${params}`).then(res=> res?.data);  
    },
    async fetchCities(page=1){
        const states=await request.get(`/api/cities?populate[country_state]=true&populate[state_district]=true&pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let state = response.data.data[i]
                   let {country_state ,state_district,...attributes} = state.attributes;
                   proData.data.push({
                        id:state.id,
                        ...attributes,
                        state:{
                            id:country_state.data?.id,
                            ...country_state.data?.attributes,
                        },
                        district:{
                            id:state_district.data?.id,
                            ...state_district.data?.attributes,
                        }
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },

    async allDistricts(params={}){
        params = qs.stringify(params, {
            encodeValuesOnly: true, // prettify URL
          });
        return request.get(`/api/state-districts?${params}`).then(res=> res?.data);  
      },
    async fetchDistricts(page=1){
        const states=await request.get(`/api/state-districts?populate[country_state]=true&pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let state = response.data.data[i]
                   let {country_state ,state_district,...attributes} = state.attributes;
                   proData.data.push({
                        id:state.id,
                        ...attributes,
                        state:{
                            id:country_state.data?.id,
                            ...country_state.data?.attributes,
                        }
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },
    async fetchCountryStates(params={}){
        params = qs.stringify(params, {
            encodeValuesOnly: true, // prettify URL
          });
        await request.get(`/api/country-states?${params}`).then(res=> res?.data)
    },
    fetchAllStates(paramsData={}){
        
        const params = qs.stringify(paramsData, {
            encodeValuesOnly: true, // prettify URL
          });
          console.log(paramsData, params)
        return request.get(`/api/country-states?${params}`).then(res=> res?.data);
    },
    async fetchStates(page=1) {        
        const states=await request.get(`/api/country-states?pagination[page]=${page}`).then((response)=>{
            let proData={
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
            if(response.data){
                for(let i in response.data.data){
                   let state = response.data.data[i]
                   proData.data.push({
                        id:state.id,
                        ...state.attributes
                   })
                } 
                proData.pagination=  response.data.meta.pagination
            }
            return proData;
        }).catch ((error) =>{ 
            return {
                data: [],
                pagination: {
                  page: 1,
                  pageSize: 25,
                  pageCount: 1,
                  total: 0,
                },
            };
        })        
        return states;
    },
    async fetchSiteSettings() {        
        const setting=await request.get('/api/site-setting').then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data.data.attributes; 
            }
            return proData;
        }).catch ((error) =>{ 
            return {};
        })        
        return setting;
    },
    async fetchSocialIcons() {        
        const socialicon=await request.get('/api/social-link').then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data.data.attributes; 
            }
            return proData;
        }).catch ((error) =>{ 
            return {}; 
        })        
        return socialicon;
    },
    async subscribeNewsletter(user) {        
        const subscription=await request.post('/api/auth/local/register',user).then((response)=>{
            let proData={};
            if(response.data){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return subscription;
    }, 
    async uploadMedia(formMedia) {        
        const files=await multipart.post('/api/upload/',formMedia).then((response)=>{
            let uploads=[];
            if(response.data){
                uploads=response.data; 
            } 
            return uploads;
        }).catch ((error) =>{  
            return [];
        })        
        return files;
    }, 
    async changePassword(formdata) {      
        const sended=await authrequest(Catalog.Session.token).post('/api/auth/change-password',formdata).then((response)=>{
            const sendmail=response.data; 
            return sendmail;
        }).catch ((error) =>{    
            return error.response.data;
        })        
        return sended;     

    },
    async authCheck() {      
        const auth=await authrequest(Catalog.Session.token).get('/api/users/me').then((response)=>{ 
            return response.data;
        }).catch ((error) =>{    
            return error.response.data;
        })        
        return auth; 
    },

    async updateUser(user,id) {        
        const userdata=await request.put(`/api/users/${id}`,user).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async resetPassword(formdata) {      
        const sended=await gustrequest.post('/api/auth/reset-password',formdata).then((response)=>{
            let sendmail=false;
            if(response.status===200){
                sendmail=true;
            } 
            return sendmail;
        }).catch ((error) =>{   
            return false;
        })        
        return sended;     

    },
    async forgetPassword(email) {        
        const sended=await gustrequest.post('/api/auth/forgot-password',email).then((response)=>{
            let sendmail=false;
            if(response.status===200){
                sendmail=true;
            } 
            return sendmail;
        }).catch ((error) =>{   
            return false;
        })        
        return sended;
    }, 
    async loginUser(auth) {        
        const userdata=await gustrequest.post('/api/auth/local',auth).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{   
            return error.response.data;
        })        
        return userdata;
    }, 
    registerUser(user,settings) {        
        return request.post('/api/users',user).then(async (response)=>{
            let proData= response.data;
            return SiteService.registerUserMail(proData,settings).then(mailRes=>proData)
        });
    }, 

    async registerUserMail(user,settings) { 
  
        
        const message = `
        <table>
            <tr> 
                <td colspan="2">New User Registerd</td>
            </tr>
            <tr>
                <td>Name</td>
                <td>${user.first_name} ${user.last_name}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td>${user.email}</td>
            </tr>
        </table> 
        ` ;
        const messageText=`New User Registerd,\n Name :${user.first_name} ${user.last_name} ,\n Email :${user.email}   `;
        const senemail= settings.contact_email;
        const sensubject= "New User Registerd";

        return Promise.all([
            gustrequest.post(`api/auth/send-email-confirmation`,{
                email:user.email
            }),
            request.post('/api/email',{
                to: senemail,
                subject:sensubject,
                text: messageText,
                html: message
              }).then((response)=>{
                let proData={
                    success:false,
                };
                if(response.status===200){
                    proData={
                        success:true,
                    }; 
                } 
                return proData;
            })
        ])
    },
    async registerLawyer(profile) {        
        const userdata=await request.post('/api/lawyers',{data:profile}).then((response)=>{
            let proData={};
            if(response.data){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async registerFirm(profile) {        
        const userdata=await request.post('/api/firms',{data:profile}).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async updateRole(role,id) {        
        const userdata=await request.put(`/api/users/${id}`,{data:{role:role}}).then((response)=>{
            let proData={};
            if(response.status===200){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return {};
        })        
        return userdata;
    }, 
    async checkEmailExist(email) {        
        const userdata=await request.get(`/api/users?filters[email][$eq]=${email}&fields[0]=email`).then((response)=>{
            if(response.status===200&&response.data.length>0){ 
                return true
            } else{
                return false
            }
        }).catch ((error) =>{  
            return false;
        })        
        return userdata;
    },
    async subscribeComingSoon(user) {        
        const subscription=await request.post('/api/coming-soon-users',{
            data:user
        }).then((response)=>{
            let proData={};
            if(response.data){
                proData=response.data; 
            } 
            return proData;
        }).catch ((error) =>{  
            return null;
        })        
        return subscription;
    }, 
    async fetchPagebySlug(slug) {        
        const page=await request.get(`/api/hosted-pages?filters[name][$eq]=${slug}&sort[0]=id:desc`).then((response)=>{
            let proData={};
            if (
                response.status === 200 &&
                response.data.data &&
                response.data.data[0]
              ) {
                proData=response.data.data[0].attributes;
                proData.id=response.data.data[0].id 
            } 
            return proData;
        }).catch ((error) =>{  
            return null;
        })        
        return page;
    }, 
}